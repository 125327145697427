import React from "react"
import "./tiggbee-footer.css"
// import tiggbee_footer_logo from '../images/tiggbee-footer-logo.png';
import { Link } from "gatsby"

export class TiggbeeFooter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const html = (
      <>
        <div className="container-fluid footer-containter">
          <div className="row">
            <div className="col-md-4 footer-col">
              <div
                className="footer-tiggbee-logo-container"
                style={{ marginTop: "28px" }}
              >
                <Link to="/" className="footer-tiggbee-logo-link">
                  <img alt="" src={"/tiggbee-footer-logo.png"} />
                </Link>
              </div>
            </div>
            <div className="col-md-4 footer-col">
              <div className="footer-link footer-horizon-link-row">
                <div>
                  <Link to="/contact-us-page/">Contact us</Link>
                </div>
                <div>
                  <a href="https://event.tiggbee.com/">Sign in</a>
                </div>
                <div className="footer-hr"></div>
              </div>
              <div className="footer-sublink footer-horizon-link-row">
                <div>
                  <Link to="/privacy-policy-page">Privacy Policy</Link>
                </div>
                <div>
                  <Link to="/terms-and-conditions-page">
                    Terms & Conditions
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 footer-create-accout-col">
              <div className="footer-create-account-button-container">
                <a href="https://event.tiggbee.com/authentication/createaccount">
                  Create account
                </a>
                <div>
                  <div>
                    <a
                      href="https://www.linkedin.com/company/tiggbee"
                      target="_blank"
                    >
                      <img
                        alt="LinkedIn"
                        src="/social-LinkedIn.png"
                        width="25px"
                      />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.instagram.com/tiggbee" target="_blank">
                      <img
                        alt="Instagram"
                        src="/social-Instagram.png"
                        width="22px"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.facebook.com/tiggbeellc"
                      target="_blank"
                    >
                      <img
                        alt="Facebook"
                        src="/social-Facebook.png"
                        width="26px"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
    return html
  }
}
