import React from "react"
import "./tiggbee-header.css"
import { Link } from "gatsby"

export class TiggbeeHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const html = (
      <>
        <nav className="navbar navbar-light navbar-expand-lg">
          <Link to="/" className="navbar-brand" href="#">
            <img alt="tiggbee" src={"/tiggbee-com_logo.svg"} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link
                  to="/"
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Learn More
                  <img
                    alt=""
                    src={"/nav-arrow.png"}
                    className="nav-link-arrow"
                  />
                  <img
                    alt=""
                    src={"/nav-arrow-hover.png"}
                    className="nav-link-arrow-hover"
                  />
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Link to="/business-page/" className="dropdown-item" href="#">
                    For Business
                  </Link>
                  <Link to="/school-page/" className="dropdown-item" href="#">
                    For Schools & Educators
                  </Link>
                  <Link to="/student-page/" className="dropdown-item" href="#">
                    For Students & Parents
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/contact-us-page/" className="nav-link" href="#">
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/career-portal-page/" className="nav-link" href="#">
                  Career Portal
                </Link>
              </li>
              <li className="nav-item nav-sign-in">
                <Link
                  className="nav-link"
                  href="https://event.tiggbee.com/authentication/signin"
                >
                  Sign In
                </Link>
              </li>
              <li className="nav-item nav-create-account">
                <a
                  href="https://event.tiggbee.com/authentication/createaccount"
                  className="nav-link nav-link-button"
                >
                  Create Account
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </>
    )
    return html
  }
}
